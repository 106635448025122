<template>
  <div>
    <div>
      <ConnectWallet />
    </div>

    <div class="z-0 mp-10">
      <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        style="
          background-image: url('https://media.discordapp.net/attachments/1080058777971212370/1118830759621435502/Nisa_Pic_02.png?width=1648&height=1236');
        "
      >
        <div class="text-center">
          <h2
            class="max-w-lg mb-6 font-sans text-xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-white"
          >
            @nisa_zhang
          </h2>

          <h2
            class="font-sans text-3xl font-bold sm:text-7xl md:mx-auto text-white"
          >
            Nisa Zhang
          </h2>
        </div>
      </div>

      <div class="w-full bg-gray-400">
        <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
          <div class="flex items-center justify-center">
            <div class="p-4">
              <h2
                class="max-w-2xl text-xl font-bold tracking-tight sm:text-3xl md:mx-auto mt-10 text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-blue-500 mt-10"
              >
                Meet Nisa Zhang, a 22-years-old woman who is a delightful blend
                of Thai and Chinese heritage. Born on September 12, 2000, in
                Bangkok, Thailand, Nisa has grown up embracing both cultures and
                cherishing the unique traditions and customs that come with
                them.
              </h2>
              <br />

              <h2
                class="max-w-2xl font-sans text-lg tracking-tight sm:text-2xl md:mx-auto text-white"
              >
                At 5'6" tall and weighing 115 pounds, Nisa has a slender and
                graceful figure that reflects her love for fitness and healthy
                living. She enjoys trying new workout routines, from yoga to
                kickboxing, and also loves to explore the great outdoors through
                hiking and camping.
              </h2>

              <br />

              <div class="text-center">
                <hr class="h-px my-4 border-green-700 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-white"
                >
                  Age : 22 years old
                </h2>
                <hr class="h-px my-4 border-green-700 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-white"
                >
                  Blood Type : AB
                </h2>
                <hr class="h-px my-4 border-green-700 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-white"
                >
                  Hight : 170 cm. , Weight : 52 kg.
                </h2>
                <hr class="h-px my-4 border-green-700 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-white"
                >
                  Chest : 36” , Waist : 24” , Hips : 35”
                </h2>

                <hr class="h-px my-4 border-green-700 border" />
              </div>
            </div>
          </div>
          <div class="flex justify-center">
            <img
              src="https://cdn.discordapp.com/attachments/1080058777971212370/1118832903204388894/Nisa_Pic_02.png"
              class="h-96 lg:h-screen"
            />
          </div>
        </div>
      </div>

      <div class="w-full bg-black pt-24 pb-24">
        <div
          class="mx-auto sm:max-w-2xl lg:max-w-screen-lg md:px-24 lg:px-8 text-white p-4"
        >
          <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <div>
              <div class="grid grid-cols-2 gap-1 lg:grid-cols-2">
                <div>
                  <img
                    src="https://media.discordapp.net/attachments/1080058777971212370/1118830298872959036/Nisa_Pic_01.png?width=1564&height=1236"
                    class="h-40 w-40 lg:w-56 lg:h-56 rounded-full object-cover ease-in-out"
                  />
                </div>
                <div class="h-full w-full flex items-center">
                  <div>
                    <h2
                      class="max-w-2xl font-sans text-lg tracking-tight sm:text-2xl md:mx-auto text-white"
                    >
                      @nisa.zhang
                    </h2>

                    <h2
                      class="max-w-2xl font-sans text-4xl tracking-tight md:mx-auto text-white mt-3"
                    >
                      50K <b class="text-sm">followers </b>
                    </h2>
                    <a
                      href="https://www.instagram.com/nisa.zhang/"
                      target="_blank"
                      class="inline-block p-px bg-gradient-to-t from-gray-300 via-green-600 to-gray-300 rounded-xl mt-5"
                    >
                      <div
                        class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-xl text-gray-200 bg-black cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                      >
                        Follow
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="h-full w-full flex items-center">
              <div>
                <h2
                  class="text-lg tracking-tight sm:text-xl md:mx-auto text-white mt-6"
                >
                  Nisa is also a gifted linguist, fluent in Thai, Chinese,
                  English, and French. Her ability to speak multiple languages
                  has opened doors for her to work as a translator and
                  interpreter for international organizations and
                  events.💖🎶🌈☔️🍕🍷⚽️✈️🎡📷
                </h2>

                <div class="mt-5">
                  <div class="flex">
                    <a class="m-2 cursor-pointer" href="" target="_blank">
                      <i class="fab fa-twitter" style="font-size: 40px"></i>
                    </a>

                    <a class="m-2 cursor-pointer" href="" target="_blank">
                      <i class="fab fa-reddit" style="font-size: 40px"></i>
                    </a>

                    <a class="m-2 cursor-pointer" href="" target="_blank">
                      <i class="fab fa-youtube" style="font-size: 40px"></i>
                    </a>

                    <a class="m-2 cursor-pointer" href="" target="_blank">
                      <i class="fab fa-instagram" style="font-size: 40px"></i>
                    </a>

                    <a class="m-2 cursor-pointer" href="" target="_blank">
                      <i class="fab fa-facebook" style="font-size: 40px"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="bg-blue-400 w-full flex justify-center items-center pt-40 pb-48"
      >
        <div class="text-center">
          <h2
            class="font-sans text-3xl font-bold sm:text-6xl md:mx-auto text-white"
          >
            Nisa Zhang
          </h2>
          <h2
            class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-white"
          >
            @nisa_zhang
          </h2>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="-mt-48 flex justify-center">
          <img
            src="https://media.discordapp.net/attachments/1080058777971212370/1118830298872959036/Nisa_Pic_01.png?width=1564&height=1236"
            class="inset-0 object-cover ease-in-out"
          />
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="">
          <hr class="h-px my-4 border-blue-400 border" />

          <h2
            class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-gray-200"
          >
            VIRTUAL INFLUENCERS
          </h2>
          <hr class="h-px my-4 border-blue-400 border" />
        </div>

        <div class="grid grid-cols-1 gap-2 lg:grid-cols-2 md:grid-cols-2">
          <div>
            <div
              class="transition duration-300 ease-in-out transform-gpu hover:-translate-y-1 cursor-pointer rounded-sm rounded"
              style="margin-top: 10px"
              v-for="s in models"
              :key="s"
            >
              <a>
                <img :src="s.img" alt="Image 1" class="h-full w-full" />
              </a>
            </div>
          </div>

          <div>
            <div
              class="transition duration-300 ease-in-out transform-gpu hover:-translate-y-1 cursor-pointer rounded-sm rounded"
              style="margin-top: 10px"
              v-for="s in models_2"
              :key="s"
            >
              <a>
                <img :src="s.img" alt="Image 1" class="h-full w-full" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";

export default {
  data() {
    return {
      models: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112630379757719602/00103.png",
          name: "Nisa Zhang",
          nickname: "nisa_zhang",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1113802277891883069/Nisa_Cream_02.png",
          name: "FN Meka",
          nickname: "fnmeka",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112630510942945300/00108.png",
          name: "Lu of Magalu",
          nickname: "magazineluiza",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1113802304840273930/Nisa_Cream_04.png",
          name: "Barbie",
          nickname: "barbie",
        },
      ],
      models_2: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112631920157786143/00090-2919982846.png",
          name: "Any Malu",
          nickname: "anymalu_real",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1113807236859428925/00239.png",
          name: "CB of Casas Bahia",
          nickname: "casasbahia",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112631175421378700/00001-3782904770.png",
          name: "Miquela Sousa",
          nickname: "lilmiquela",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1112630379757719602/00103.png",
          name: "Hatsune Miku",
          nickname: "cfm_miku_official",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
  },
  methods: {
    linkDetail(s) {
      if (s.price == "free") {
        return "/promptFree/" + s._id;
      } else {
        return "/prompt/" + s._id;
      }
    },
  },
  created() {},
};
</script>
